'use client';

import { ThemeProvider } from '@material-tailwind/react';
import { Provider as ReduxProvider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from 'components/organisms/global/loader';
import { store } from 'redux/store';
import { customTheme } from 'styles/themes/theme';
const persistedStore = persistStore(store);

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider value={customTheme}>
      <ReduxProvider store={store}>
        <PersistGate loading={<Loading />} persistor={persistedStore}>
          <ParallaxProvider>{children}</ParallaxProvider>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>
  );
}
