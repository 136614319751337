'use client';

import Script from 'next/script';
import { parseLogLevels } from 'helpers/datadog/parse-log-levels';

export function Scripts() {
  return (
    <>
      <Script id="data-dog" strategy="afterInteractive">
        {`(function(h,o,u,n,d) {
                  h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                  d=o.createElement(u);d.async=1;d.src=n
                  n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
                window.DD_RUM.onReady(function() {
                  window.DD_RUM.init({
                    clientToken: '${process.env.NEXT_PUBLIC_DD_TOKEN}',
                    applicationId: '${process.env.NEXT_PUBLIC_DD_APPID}',
                    site: 'datadoghq.com',
                    service: 'rum-headless',
                    env: '${process.env.NEXT_PUBLIC_CUST_ENV}',
                    version: '0.1.0',
                    sessionSampleRate: ${process.env.NEXT_PUBLIC_DD_RUM_SAMPLE_RATE || '10'} ,
                    sessionReplaySampleRate: ${process.env.NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE || '10'},
                    trackUserInteractions: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_USER_INTERACTIONS || 'true'},
                    trackResources: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_RESOURCES || 'true'},
                    trackLongTasks: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_LONG_TASKS || 'true'},
                    defaultPrivacyLevel: '${process.env.NEXT_PUBLIC_DD_RUM_PRIVACY_LEVEL || 'mask-user-input'}',
                  });
                })
              `}
      </Script>
      <Script id="data-dog-logs">
        {` (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-logs.js','DD_LOGS')
      window.DD_LOGS.onReady(function() {
          window.DD_LOGS.init({
            clientToken: '${process.env.NEXT_PUBLIC_DD_TOKEN}',
            site: 'datadoghq.com',
            forwardErrorsToLogs: ${process.env.NEXT_PUBLIC_DD_LOGS_ENABLED || 'true'},
            sessionSampleRate: ${process.env.NEXT_PUBLIC_DD_LOGS_SAMPLE_RATE || '100'},
            forwardConsoleLogs: ${parseLogLevels(process.env.NEXT_PUBLIC_DD_LOGS_CONSOLE_LOGS_LEVELS)},
          })
        })`}
      </Script>
    </>
  );
}
